.wrapper {
  display: grid;
  /* height changed from 100vh to 30% because of extra section -revert when recruitment is over */
  height: 30%;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .wrapper {
    height: calc(100vh);
  }
}

@media only screen and (max-width: 300px) {
  .wrapper {
    height: calc(170vh);
  }
}

.accordion {
  display: grid;
  grid-template-columns: repeat(2, 45vw);
  grid-template-rows: repeat(3, 25vh);
  align-items: center;
  margin-bottom: auto;
}

@media only screen and (max-width: 750px) {
  .accordion {
    grid-template-columns: repeat(1, 90vw);
    grid-template-rows: repeat(6, 125px);
  }
}

@media only screen and (max-width: 300px) {
  .accordion {
    grid-template-rows: repeat(6, 170px);
  }
}

.item {
  background: #002245;
  padding: 10px 20px;
  width: 40vw;
  transition: 1s ease-in;
}

@media only screen and (max-width: 750px) {
  .item {
    width: 87vw;
    padding: 0px 1.5vw;
  }
}

.title {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

h1 {
  font-size: 32px;
}

@media only screen and (max-width: 750px) {
  h1 {
    font-size: 25px;
  }
}

h2 {
  font-size: 25px;
}

@media only screen and (max-width: 1300px) {
  h2 {
    font-size: 1.5vw;
  }
}

@media only screen and (max-width: 750px) {
  h2 {
    font-size: 15px;
  }
}

.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

@media only screen and (max-width: 750px) {
  .content.show {
    font-size: 12px;
  }
}

.content {
  color: white;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

@media only screen and (max-width: 750px) {
  .content {
    font-size: 12px;
  }
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}
